import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import HomeComp from "../components/Home";

const IndexPage = () => {
  return (
    <Layout
      clsOverflow={true}
    >
      <SEO
        title="Artista visual"
        description="Georgina Quintana es una artista visual"
        works='georgina quintana, artista visual, pintura, México, Oaxaca'
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
      />

      <HomeComp/>

    </Layout>
  )
}

export default IndexPage
