import React, {useState} from 'react';
import {Column, Columns} from 'bloomer';
import CollageBigComp from '../Collage';
import {GlobalStyle} from '../../styles/GlobalStyles';
import {Style, ContBookTitle} from './styles';
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link';

const HomeComp = () => {
  const [bookOpenBig, setBookOpenBig] = useState(false);
  const [testState, setTestState] = useState(false);

  const handleOpenBook = (val) => {
    console.log('handleOpenBook ->', val);
    setBookOpenBig(!bookOpenBig);
  };

  return (
    <div className="cont-page page-home">
      <GlobalStyle/>
      <Style/>

      <TransitionState>
        {state => {
          // console.log('state.entry.state.bookOpenBig ->', state.entry.state.bookOpenBig);
          if(state.entry.state.bookOpenBig){
            setBookOpenBig(state.entry.state.bookOpenBig);
          }
        }}
      </TransitionState>
      {/*<TransitionState>
        {({ mount, transitionStatus }) => {
          console.log('transitionStatus ->', transitionStatus)
          console.log('mount ->', mount)
        }}
      </TransitionState>*/}

      <Columns className="columns-responsive full-height flex-center" isMultiline={true} isCentered={true}>
        <Column className={'full-height flex-center'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          {
            bookOpenBig
              ? <CollageBigComp/>
              : <ContBookTitle onClick={() => handleOpenBook()}>
                  <div>mi vértigo cotidiano</div>
                </ContBookTitle>
          }
        </Column>
      </Columns>
      <TransitionLink
        id={'link-anteportada'}
        to="/anteportada"
        exit={{length: 0.5}}
        entry={{delay: 0.5}}
      />
    </div>
  );
};
export default HomeComp;
