import React from 'react';
import {Column, Columns} from "bloomer";
import TransitionLink from 'gatsby-plugin-transition-link';
import {
  GlobalStyleLinkCollage,
  ContImgsCollageInBookOpen,
  ContItemImgCollage,
  ItemImgCollage,
} from './styles';
import Img_1 from '../../images/obras/obra-1/mini.jpg';
import Img_2 from '../../images/obras/obra-2/mini.jpg';
import Img_3 from '../../images/obras/obra-3/mini.jpg';
import Img_4 from '../../images/obras/obra-4/mini.jpg';
import Img_5 from '../../images/obras/obra-5/mini.jpg';
import Img_6 from '../../images/obras/obra-6/mini.jpg';
import Img_7 from '../../images/obras/obra-7/mini.jpg';
import Img_8 from '../../images/obras/obra-8/mini.jpg';
import Img_9 from '../../images/obras/obra-9/mini.jpg';
import Img_10 from '../../images/obras/obra-10/mini.jpg';
import Img_11 from '../../images/obras/obra-11/mini.jpg';
import Img_12 from '../../images/obras/obra-12/mini.jpg';
import Img_13 from '../../images/obras/obra-13/mini.png';
import Img_14 from '../../images/obras/obra-14/mini.jpg';
import Img_15 from '../../images/obras/obra-15/mini.jpg';

export const ContMiniObra = ({link, img, name}) => {
  return(
    <ContItemImgCollage>
      <TransitionLink
        className={'link-collage'}
        to={link}
        exit={{
          length: 1,
          state: { openBook: true },
          trigger: ({ node, e, exit, entry }) => console.log(node, e, exit, entry)
        }}
        entry={{
          delay: 0.6,
          state: { openBook: true },
          trigger: ({ node, e, exit, entry }) => console.log(node, e, exit, entry)
        }}
      >
        <ItemImgCollage src={img} alt={name} />
      </TransitionLink>
    </ContItemImgCollage>
  )
}

const CollageBigComp = () => {

  return (
    <ContImgsCollageInBookOpen className={'cont-obras-in-book-open'}>
      <GlobalStyleLinkCollage/>
      <Columns className="columns-responsive full-height flex-center" isMultiline={true}>
        <Column className={'padding-side-no display-contents'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <ContMiniObra link={'pagina-1'} img={Img_1} name={'Obra 1'} />
          <ContMiniObra link={'pagina-2'} img={Img_2} name={'Obra 2'} />
          <ContMiniObra link={'pagina-3'} img={Img_3} name={'Obra 3'} />
          <ContMiniObra link={'pagina-4'} img={Img_4} name={'Obra 4'} />
          <ContMiniObra link={'pagina-5'} img={Img_5} name={'Obra 5'} />
          <ContMiniObra link={'pagina-6'} img={Img_6} name={'Obra 6'} />
          <ContMiniObra link={'pagina-7'} img={Img_7} name={'Obra 7'} />
          <ContMiniObra link={'pagina-8'} img={Img_8} name={'Obra 8'} />
          <ContMiniObra link={'pagina-9'} img={Img_9} name={'Obra 9'} />
          <ContMiniObra link={'pagina-10'} img={Img_10} name={'Obra 10'} />
          <ContMiniObra link={'pagina-11'} img={Img_11} name={'Obra 11'} />
          <ContMiniObra link={'pagina-12'} img={Img_12} name={'Obra 12'} />
          <ContMiniObra link={'pagina-13'} img={Img_13} name={'Obra 13'} />
          <ContMiniObra link={'pagina-14'} img={Img_14} name={'Obra 14'} />
          <ContMiniObra link={'pagina-15'} img={Img_15} name={'Obra 15'} />
        </Column>
      </Columns>
    </ContImgsCollageInBookOpen>
  )
}

export  default CollageBigComp
