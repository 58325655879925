import styled, { createGlobalStyle }  from 'styled-components';

export const GlobalStyleCollage = createGlobalStyle`
  .cont-img-collage-1{height: 282px;}
  .cont-img-collage-2{height: 265px;}
  .cont-img-collage-3{height: 181px;}
  .cont-img-collage-4{height: 200px;}
  .cont-img-collage-5{height: 282px;}
  .cont-img-collage-6{height: 309px;}
  .cont-img-collage-7{height: 282px;}
  .cont-img-collage-8{height: 229px;}
  .cont-img-collage-9{height: 282px;}
  .cont-img-collage-10{height: 272px;}
  .cont-img-collage-11{height: 282px;}
  .cont-img-collage-12{height: 282px;}
  .cont-img-collage-13{height: 282px;}
  .cont-img-collage-14{height: 229px;}
  .cont-img-collage-15{height: 282px;}

  .img-collage-1{width: 100%;}
  .img-collage-2{width: 96%;}
  .img-collage-3{width: 100%;}
  .img-collage-4{width: 90%;}
  .img-collage-5{width: 94%;}
  .img-collage-6{width: 100%;}
  .img-collage-7{width: 94%;}
  .img-collage-8{width: 100%;}
  .img-collage-9{width: 94%;}
  .img-collage-10{width: 94%;}
  .img-collage-11{width: 90%;}
  .img-collage-12{width: 94%;}
  .img-collage-13{width: 94%;}
  .img-collage-14{width: 100%;}
  .img-collage-15{width: 94%;}
  
  @media(min-width: 300px) and (max-width: 767px){
    .cont-img-collage-1{height: 200px;}
    .cont-img-collage-2{height: 194px;}
    .cont-img-collage-3{height: 140px;}
    .cont-img-collage-4{height: 135px;}
    .cont-img-collage-5{height: 190px;}
    .cont-img-collage-6{height: 218px;}
    .cont-img-collage-7{height: 220px; justify-content: flex-start !important;}
    .cont-img-collage-8{height: 180px;}
    .cont-img-collage-9{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-10{height: 230px; justify-content: flex-start !important;}
    .cont-img-collage-11{height: 220px;}
    .cont-img-collage-12{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-13{height: 218px; justify-content: flex-start !important;}
    .cont-img-collage-14{height: 180px;}
    .cont-img-collage-15{height: 218px; justify-content: flex-end !important;}
  
    .img-collage-1{width: 90%;}
    .img-collage-2{width: 90%;}
    .img-collage-3{width: 96%;}
    .img-collage-4{width: 80%;}
    .img-collage-5{width: 90%;}
    .img-collage-6{width: 90%;}
    .img-collage-7{width: 90%;}
    .img-collage-8{width: 100%;}
    .img-collage-9{width: 90%;}
    .img-collage-10{width: 94%;}
    .img-collage-11{width: 80%;}
    .img-collage-12{width: 90%;}
    .img-collage-13{width: 94%;}
    .img-collage-14{width: 100%;}
    .img-collage-15{width: 90%;}
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .cont-img-collage-1{height: 200px;}
    .cont-img-collage-2{height: 194px;}
    .cont-img-collage-3{height: 140px;}
    .cont-img-collage-4{height: 135px;}
    .cont-img-collage-5{height: 190px;}
    .cont-img-collage-6{height: 218px;}
    .cont-img-collage-7{height: 220px; justify-content: flex-start !important;}
    .cont-img-collage-8{height: 180px;}
    .cont-img-collage-9{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-10{height: 230px; justify-content: flex-start !important;}
    .cont-img-collage-11{height: 220px;}
    .cont-img-collage-12{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-13{height: 218px; justify-content: flex-start !important;}
    .cont-img-collage-14{height: 180px;}
    .cont-img-collage-15{height: 218px; justify-content: flex-end !important;}
  
    .img-collage-1{width: 90%;}
    .img-collage-2{width: 90%;}
    .img-collage-3{width: 96%;}
    .img-collage-4{width: 80%;}
    .img-collage-5{width: 90%;}
    .img-collage-6{width: 90%;}
    .img-collage-7{width: 90%;}
    .img-collage-8{width: 100%;}
    .img-collage-9{width: 90%;}
    .img-collage-10{width: 94%;}
    .img-collage-11{width: 80%;}
    .img-collage-12{width: 90%;}
    .img-collage-13{width: 94%;}
    .img-collage-14{width: 100%;}
    .img-collage-15{width: 90%;}
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .cont-img-collage-1{height: 200px;}
    .cont-img-collage-2{height: 194px;}
    .cont-img-collage-3{height: 140px;}
    .cont-img-collage-4{height: 135px;}
    .cont-img-collage-5{height: 190px;}
    .cont-img-collage-6{height: 218px;}
    .cont-img-collage-7{height: 220px; justify-content: flex-start !important;}
    .cont-img-collage-8{height: 180px;}
    .cont-img-collage-9{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-10{height: 230px; justify-content: flex-start !important;}
    .cont-img-collage-11{height: 220px;}
    .cont-img-collage-12{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-13{height: 218px; justify-content: flex-start !important;}
    .cont-img-collage-14{height: 180px;}
    .cont-img-collage-15{height: 218px; justify-content: flex-end !important;}
  
    .img-collage-1{width: 90%;}
    .img-collage-2{width: 90%;}
    .img-collage-3{width: 96%;}
    .img-collage-4{width: 80%;}
    .img-collage-5{width: 90%;}
    .img-collage-6{width: 90%;}
    .img-collage-7{width: 90%;}
    .img-collage-8{width: 100%;}
    .img-collage-9{width: 90%;}
    .img-collage-10{width: 94%;}
    .img-collage-11{width: 80%;}
    .img-collage-12{width: 90%;}
    .img-collage-13{width: 94%;}
    .img-collage-14{width: 100%;}
    .img-collage-15{width: 90%;}
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .cont-img-collage-1{height: 200px;}
    .cont-img-collage-2{height: 194px;}
    .cont-img-collage-3{height: 140px;}
    .cont-img-collage-4{height: 135px;}
    .cont-img-collage-5{height: 190px;}
    .cont-img-collage-6{height: 218px;}
    .cont-img-collage-7{height: 220px; justify-content: flex-start !important;}
    .cont-img-collage-8{height: 180px;}
    .cont-img-collage-9{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-10{height: 230px; justify-content: flex-start !important;}
    .cont-img-collage-11{height: 220px;}
    .cont-img-collage-12{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-13{height: 218px; justify-content: flex-start !important;}
    .cont-img-collage-14{height: 180px;}
    .cont-img-collage-15{height: 218px; justify-content: flex-end !important;}
  
    .img-collage-1{width: 90%;}
    .img-collage-2{width: 90%;}
    .img-collage-3{width: 96%;}
    .img-collage-4{width: 80%;}
    .img-collage-5{width: 90%;}
    .img-collage-6{width: 90%;}
    .img-collage-7{width: 90%;}
    .img-collage-8{width: 100%;}
    .img-collage-9{width: 90%;}
    .img-collage-10{width: 94%;}
    .img-collage-11{width: 80%;}
    .img-collage-12{width: 90%;}
    .img-collage-13{width: 94%;}
    .img-collage-14{width: 100%;}
    .img-collage-15{width: 90%;}
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .cont-img-collage-1{height: 200px;}
    .cont-img-collage-2{height: 194px;}
    .cont-img-collage-3{height: 140px;}
    .cont-img-collage-4{height: 135px;}
    .cont-img-collage-5{height: 190px;}
    .cont-img-collage-6{height: 218px;}
    .cont-img-collage-7{height: 220px; justify-content: flex-start !important;}
    .cont-img-collage-8{height: 180px;}
    .cont-img-collage-9{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-10{height: 230px; justify-content: flex-start !important;}
    .cont-img-collage-11{height: 220px;}
    .cont-img-collage-12{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-13{height: 218px; justify-content: flex-start !important;}
    .cont-img-collage-14{height: 180px;}
    .cont-img-collage-15{height: 218px; justify-content: flex-end !important;}
  
    .img-collage-1{width: 90%;}
    .img-collage-2{width: 90%;}
    .img-collage-3{width: 96%;}
    .img-collage-4{width: 80%;}
    .img-collage-5{width: 90%;}
    .img-collage-6{width: 90%;}
    .img-collage-7{width: 90%;}
    .img-collage-8{width: 100%;}
    .img-collage-9{width: 90%;}
    .img-collage-10{width: 94%;}
    .img-collage-11{width: 80%;}
    .img-collage-12{width: 90%;}
    .img-collage-13{width: 94%;}
    .img-collage-14{width: 100%;}
    .img-collage-15{width: 90%;}
  }
  @media(min-width: 1920px){
    .cont-img-collage-1{height: 200px;}
    .cont-img-collage-2{height: 194px;}
    .cont-img-collage-3{height: 140px;}
    .cont-img-collage-4{height: 135px;}
    .cont-img-collage-5{height: 190px;}
    .cont-img-collage-6{height: 218px;}
    .cont-img-collage-7{height: 220px; justify-content: flex-start !important;}
    .cont-img-collage-8{height: 180px;}
    .cont-img-collage-9{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-10{height: 230px; justify-content: flex-start !important;}
    .cont-img-collage-11{height: 220px;}
    .cont-img-collage-12{height: 218px; justify-content: flex-end !important;}
    .cont-img-collage-13{height: 218px; justify-content: flex-start !important;}
    .cont-img-collage-14{height: 180px;}
    .cont-img-collage-15{height: 218px; justify-content: flex-end !important;}
  
    .img-collage-1{width: 90%;}
    .img-collage-2{width: 90%;}
    .img-collage-3{width: 96%;}
    .img-collage-4{width: 80%;}
    .img-collage-5{width: 90%;}
    .img-collage-6{width: 90%;}
    .img-collage-7{width: 90%;}
    .img-collage-8{width: 100%;}
    .img-collage-9{width: 90%;}
    .img-collage-10{width: 94%;}
    .img-collage-11{width: 80%;}
    .img-collage-12{width: 90%;}
    .img-collage-13{width: 94%;}
    .img-collage-14{width: 100%;}
    .img-collage-15{width: 90%;}
  }
`;
export const GlobalStyleLinkCollage = createGlobalStyle`
  .link-collage{
    width: 100%;
    height: auto;
  }
`
export const ContImgsCollageInBookOpen = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

export const ContItemImgCollage = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: calc(100% / 3);
    height: auto;
    padding: 0 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: calc(100% / 3);
    height: auto;
    padding: 0 15px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: calc(100% / 3);
    height: auto;
    padding: 0 15px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: calc(100% / 5);
    height: auto;
    padding: 0 15px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: calc(100% / 5);
    height: auto;
    padding: 0 15px;
  }
  @media(min-width: 1920px){
    width: calc(100% / 5);
    height: auto;
    padding: 0 22px;
  }
`;

export const ItemImgCollage = styled.img`
  width: 100%;
  height: auto;
`;
